import React, { Component } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';

import 'react-calendar-heatmap/dist/styles.css';
import './CalendarHeatmapComponent.css';

export default class CalendarHeatmapComponent extends Component {
  constructor(props){
    super(props);
    this.today = new Date();
    this.randomValues = this.getRange(200).map(index => {
        return {
          date: this.shiftDate(this.today, -index),
          count: this.getRandomInt(1, 3),
        };
      });
  }
  
  shiftDate(date, numDays) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return newDate;
  }
  
  getRange(count) {
    return Array.from({ length: count }, (_, i) => i);
  }
  
  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  
  render() {
    return (
        <div>
          <CalendarHeatmap
            startDate={this.shiftDate(this.today, -150)}
            endDate={this.today}
            values={this.randomValues}
            classForValue={value => {
            if (!value) {
                return 'color-empty';
            }
            return `color-github-${value.count}`;
            }}
            tooltipDataAttrs= 
            { value => {
            return {
                'data-tip': `${value.date.toISOString().slice(0, 10)} has count: ${
                value.count
                }`,
              }
            } 
            }
           
            showWeekdayLabels={true}
            onClick={value => alert(`Clicked on value with count: ${value.count}`)}
        />
        <ReactTooltip />
        </div>
    );
    }
}