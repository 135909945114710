import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./components/pages/Home/Home";
import NotFound from "./components/organisms/forms/NotFound";
import Login from "./components/pages/Login/Login";
import AppliedRoute from "./components/organisms/AppliedRoute";
import Signup from "./components/pages/Login/Signup";
import NewNote from "./components/pages/Notes/NewNote";
import Notes from "./components/pages/Notes/Notes";
import AuthenticatedRoute from "./components/organisms/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/organisms/UnauthenticatedRoute";
import Settings from "./components/pages/Settings/Settings/Settings";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} />
    <AuthenticatedRoute path="/notes/new" exact component={NewNote} props={childProps} />
    <AuthenticatedRoute path="/notes/:id" exact component={Notes} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;