import React, { Component } from "react";
import { API } from "aws-amplify";
import { Panel } from "react-bootstrap";
import "./DayPhrases.css";

export default class DayPhrases extends Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    async componentDidMount() {
        try{
            const dayphrase = await this.getDayPhrase();
            const { dayphraseid, language, phrase, topic, imageurl} = dayphrase;
            this.setState({
                dayphrase,
                dayphraseid,
                language,
                phrase,
                topic,
                imageurl
            });
        }catch(e){
            alert(e);
        }
    }

    getDayPhrase(){
        return API.get("dayphrases", `/dayphrase/random/`);
    }

    updateDayPhrase(){
        try{
            // const dayphrase = this.getDayPhrase();
            // const { dayphraseid, language, phrase, topic, imageurl} = dayphrase;
            
        }catch(e){
            alert(e);
        }
    }
    render() {
        return(
            <Panel className="dayphrases">
                { this.state.dayphrase && 
                <Panel.Body className="dayPhrasesCard">
                    <span>{this.state.phrase}</span>
                    <p>{this.state.topic}</p>
                    </Panel.Body>
                    }
            </Panel>
        );
    }
}