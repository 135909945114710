import config from "./config";
import React, { Component, Fragment } from "react";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem, FormControl, FormGroup, Button, MenuItem, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }
  
  async componentDidMount() {
    this.loadFacebookSDK();
  
    try {
      await Auth.currentAuthenticatedUser();
      this.userHasAuthenticated(true);
    } catch (e) {
      if (e !== "not authenticated") {
        alert(e);
      }
    }
  
    this.setState({ isAuthenticating: false });
  }
  
  loadFacebookSDK() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : config.social.FB,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v3.1'
      });
    };
  
    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
  }
  

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();
  
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }

  render() {
  const childProps = {
    isAuthenticated: this.state.isAuthenticated,
    userHasAuthenticated: this.userHasAuthenticated
  };

  // https://medium.com/better-humans/replace-your-to-do-list-with-interstitial-journaling-to-increase-productivity-4e43109d15ef->
                     
  return (
    !this.state.isAuthenticating &&
    <div className="App AppHeader">
      <Navbar fluid collapseOnSelect inverse fixedTop>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">Brainstorms Lab - Work on Your Ideas in different ways</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            {this.state.isAuthenticated
              ? <Fragment>
                <Navbar.Form pullLeft>
                    <FormGroup>
                      <FormControl type="text" placeholder="Search" />
                    </FormGroup>{' '}
                  <Button type="submit">Search</Button>
                </Navbar.Form>
                    <NavDropdown eventKey={3} title="Menu" id="basic-nav-dropdown">
                      <LinkContainer to="/settings">
                        <MenuItem eventKey={3.1}>Settings</MenuItem>
                      </LinkContainer>
                      <MenuItem eventKey={3.2}>Create Access</MenuItem>
                      <MenuItem eventKey={3.3}>Select Tools</MenuItem>
                      <MenuItem eventKey={3.4}>Journalist Version</MenuItem> 
                      <MenuItem divider />
                      <MenuItem eventKey={3.3}>Help</MenuItem>
                    </NavDropdown>
                  <NavItem onClick={this.handleLogout}>Logout</NavItem>
                  
                </Fragment>
              : <Fragment>
                  <LinkContainer to="/signup">
                    <NavItem>Signup</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <NavItem>Login</NavItem>
                  </LinkContainer>
                </Fragment>
            }
          </Nav>
          
        </Navbar.Collapse>
      </Navbar>
      <Routes childProps={childProps} />
    </div>
  );
}

}

export default withRouter(App);