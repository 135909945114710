import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Badge } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Home.css';
import { API } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { WidthProvider, Responsive } from 'react-grid-layout';
import CalendarHeatmapComponent from '../../organisms/forms/calendar-heatmap/CalendarHeatmapComponent';
import Cubeloader from '../../organisms/CubeLoader/CubeLoader';
import TomatoTimer from '../../organisms/forms/TomatoTimer/TomatoTimer';
// TODO: Create Component Container for easy setting load and usage
// import ComponentsContainer from "../../ui/ComponentsContainer"
import '../../ui/gridstyle.css';
import '../../ui/resizable.css';
import DayPhrases from '../../organisms/forms/DayPhrases/DayPhrases';
import NasaPicture from '../../organisms/NasaPicture/NasaPicture';
// TODO: Implement Dark Mode https://medium.com/better-programming/how-to-implement-dark-mode-in-your-react-app-63c1a0c5e337

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      notes: [],
      notesSection: [],
      dashboardMap: [],
      layouts: JSON.parse(JSON.stringify(originalLayouts))
    };
  }
  static get defaultProps() {
    return {
      className: "layout",
      cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
      rowHeight: 30
    };
  }

  resetLayout() {
    this.setState({ layouts: {} });
  }

  onLayoutChange(layout, layouts) {
    saveToLS("layouts", layouts);
    this.setState({ layouts });
  }

  async componentDidMount() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      return;
    }

    // Loading notes and trying to generate notesSection
    try {
      const notes = await this.notes();
      this.setState({ notes });
      const notesSection = this.formatLayoutCategories(await notes).splice(0);
      console.log('Home->componentDidMount()->notesSection', notesSection);
      console.table([notesSection]);
      this.setState({ notesSection });
    } catch (error) {
      console.error('Home->notes()->', error);
    }

    try {
      const dashboardMap = await this.dashboardMap();
      this.setState({ dashboardMap });
      console.warn('Home->dashboardMap->', dashboardMap);
    } catch (error) {
      console.info('Home->dashboardMap()->', error);
    }

    this.setState({ isLoading: false });
  }

  notes() {
    return API.get('brainstorm', '/notes');
  }

  dashboardMap() {
    return API.get('brainstorm', '/dashboardMap');
  }

  renderNotesList(notes, category) {
    const notesList = notes.filter((categorie) => categorie.category === category);
    const notesListCount = notesList.length;
    return [{}].concat(notesList).map(
      (note, i) => (i !== 0
        ? (
          <LinkContainer
            key={note.noteId}
            to={`/notes/${note.noteId}`}
          >
            <ListGroupItem header={note.content.trim().split('\n')[0]}>
              {`Created: ${new Date(note.createdAt).toLocaleString()}`}
              {`Status:${note.active}`}
            </ListGroupItem>
          </LinkContainer>
        )
        : (
          <ListGroupItem key={`${i}categoryheader`}>
            <h4>
              {`Category : ${category}`}
              {' '}
              <Badge>{notesListCount}</Badge>
            </h4>
          </ListGroupItem>
        )),
    );
  }

  renderLander() {
    return (
      <div>
        <div className="lander">
          <h1>Brainstorms lab</h1>
          <p>Your ideas in different ways</p>
          <div>
            <Link to="/login" className="btn btn-info btn-lg">
              Login
            </Link>
            <Link to="/signup" className="btn btn-success btn-lg">
              Signup
            </Link>
          </div>
        </div>
      </div>
    );
  }

  formatLayoutCategories(notes) {
    const categories = [...new Set(notes.map((x) => x.category))];
    console.info('formatLayoutCategories->categories->', categories);
    const layoutCategories = {
      i: 'test',
      x: 0,
      y: 0,
      w: 12,
      h: 4,
    };
    // this is to get the string type since the object type was not working
    const stype = '';
    return [{}].concat(categories).map((category) => {
      const notesList = notes.filter((categorie) => categorie.category === category);
      const notesListCount = notesList.length;
      layoutCategories.i = category;
      layoutCategories.y = (notesListCount * 2) + 2 + 2;
      layoutCategories.h = (notesListCount * 2) + 2;
      if (typeof (layoutCategories.i) !== typeof (stype)) {
        layoutCategories.i = 'Add';
      }
      return JSON.parse(JSON.stringify(layoutCategories));
    });
  }

  renderGroups(notes) {
    const categories = [...new Set(notes.map((x) => x.category))];
    return [{}].concat(categories).map(
      (category, i) => (i !== 0
        ? (
          <div key={category} className="GridComponentContainer">
            <ListGroup key={i}>
              {!this.state.isLoading && this.renderNotesList(notes, category)}
            </ListGroup>
          </div>
        )
        : <div key={i} className="GridComponentContainer"><br /></div>),
    );
  }

  renderNotes() {
    return (
      <div>
        { !this.state.isLoading && this.renderGroups(this.state.notes) }
      </div>
    );
  }

  renderAddNotes() {
    return (
      <div className="notes GridComponentContainer" key="AddNotes">
        <LinkContainer
          key="new"
          to="/notes/new"
        >
          <ListGroupItem>
            <h4>
              <b>{'\uFF0B'}</b>
              {' '}
              Create a new note
            </h4>
          </ListGroupItem>
        </LinkContainer>
      </div>
    );
  }

  renderAddNotes2() {
    return (
      <div className="notes GridComponentContainer" key="AddNotes">
        <LinkContainer
          key="new"
          to="/notes/new"
        >
          <ListGroupItem>
            <h4>
              <b>{'\uFF0B'}</b>
              {' '}
              Create a new note
            </h4>
          </ListGroupItem>
        </LinkContainer>
      </div>
    );
  }

  renderWorkspace() {
    // , static: true is a property
    // the component layout can be a feature of showing the different layouts with the components
    // TODO: work on Tomato Timer desing is not responsive
    const dashboardMap = [this.dashboardMap(), ...this.state.notesSection];
    // this are not been load after comming from the database
    /* [
      {i: 'CubeLoader', x: 0, y: 0, w: 2, h: 7, minW:2, minH:7},
      {i: 'CalendarHeatmap', x: 0, y: 6, w: 3, h: 3, minW: 3, minH: 3},
      {i: 'TomatoTimer', x: 8, y: 4, w: 2, h: 5, minW:2, minH: 2},
      {i: 'DayPhrases', x: 8, y: 4, w: 3, h: 2},
      {i: 'AddNotes', x: 0, y: 14, w: 4, h: 2},
      ...testofComponents,
      ...this.state.notesSection
    ]; */
    console.info('states.notes->', this.state.notesSection);
    console.info('layout notes->', dashboardMap);
    return (
      <div className="Workspace"><button onClick={() => this.resetLayout()}>Reset Layout</button>
        <ResponsiveReactGridLayout className="layout" layouts={this.state.layouts}
          onLayoutChange={(layout, layouts) =>
            this.onLayoutChange(layout, layouts)
          } cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }} rowHeight={60} width={window.innerWidth - 75}>
          <div className="GridComponentContainer" key="CubeLoader">
            <Cubeloader text="hola" />
          </div>
          <div className="GridComponentContainer" key="CalendarHeatmap">
            <CalendarHeatmapComponent />
          </div>
          <div className="GridComponentContainer" key="TomatoTimer">
            <TomatoTimer
              defaultBreakLength="5"
              defaultSessionLength="25"
            />
          </div>
          <div className="GridComponentContainer" key="DayPhrases">
            <DayPhrases />
          </div>
          <div className="GridComponentContainer" key="Watch">
            <p>Salvar sus ultimas posiciones y tamanios eso sera dificil. tal vez se podria agregar el componente de reloj</p>
            <NasaPicture />
          </div>
          { this.renderAddNotes() }
          { !this.state.isLoading && this.renderGroups(this.state.notes) }
        </ResponsiveReactGridLayout>
      </div>
    );
  }

  temprender() {
    return (
      <div>
        <button onClick={() => this.resetLayout()}>Reset Layout</button>
        <ResponsiveReactGridLayout
          className="layout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={30}
          layouts={this.state.layouts}
          onLayoutChange={(layout, layouts) =>
            this.onLayoutChange(layout, layouts)
          }
        >
          <div key="1" data-grid={{ w: 2, h: 3, x: 0, y: 0, minW: 2, minH: 3 }}>
            <span className="text">1</span>
          </div>
          <div key="2" data-grid={{ w: 2, h: 3, x: 2, y: 0, minW: 2, minH: 3 }}>
            <span className="text">2</span>
          </div>
          <div key="3" data-grid={{ w: 2, h: 3, x: 4, y: 0, minW: 2, minH: 3 }}>
            <span className="text">3</span>
          </div>
          <div key="4" data-grid={{ w: 2, h: 3, x: 6, y: 0, minW: 2, minH: 3 }}>
            <span className="text">4</span>
          </div>
          <div key="5" data-grid={{ w: 2, h: 3, x: 8, y: 0, minW: 2, minH: 3 }}>
            <span className="text">5</span>
          </div>
        </ResponsiveReactGridLayout>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? !this.state.isLoading && this.renderWorkspace() : this.renderLander()}
      </div>
    );
  }
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}
