import React, { Component } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, Col, InputGroup  } from "react-bootstrap";
import LoaderButton from "../../ui/LoaderButton/LoaderButton";
import config from "../../../config";
import "./NewNote.css";
import { s3Upload } from "../../../libs/awsLib";

export default class NewNote extends Component {
    constructor(props) {
      super(props);
  
      this.file = null;
  
      this.state = {
        isLoading: null,
        content: "",
        category: "",
        topic: "",
        tags: "",
        active: 0,
        order: 0,
        section: "list",
        limitDate: Date.now(),
        finishDate: Date.now(),
        assignedDate: Date.now()
      };
    }
  
    createNote(note) {
      return API.post("brainstorm", "/notes", {
        body: note
      });
    }
  
    validateForm() {
      return this.state.content.length > 0;
    }
  
    handleChange = event => {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
  
    handleFileChange = event => {
      this.file = event.target.files[0];
    }
  
    handleSubmit = async event => {
        event.preventDefault();
      
        if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
          alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
          return;
        }
      
        this.setState({ isLoading: true });
      
        try {
          const attachment = this.file
            ? await s3Upload(this.file)
            : null;
      
          await this.createNote({
            attachment,
            content: this.state.content,
            category: this.state.category,
            topic: this.state.topic,
            tags: this.state.tags,
            active: this.state.active,
            order: this.state.order,
            section: this.state.section,
            limitdate: this.state.limitDate,
            finishdate: this.state.finishDate,
            assigneddate: this.state.assignedDate
          });
          this.props.history.push("/");
        } catch (e) {
          alert(e);
          this.setState({ isLoading: false });
        }
      }
  
    render() {
      return (
        <div className="NewNote">
          <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="category">
            <Col componentClass={ControlLabel} sm={2}>
              Category
            </Col>
            <Col sm={10}>
              <FormControl
                type="text"
                onChange={this.handleChange}
                value={this.state.category}
                componentClass="input"
                placeholder="Category"
              />
              </Col>
            </FormGroup>
            <FormGroup controlId="topic">
              <Col componentClass={ControlLabel} sm={2}>
                Subject
              </Col>
              <Col sm={10}>
                <FormControl
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.topic}
                  componentClass="input"
                  placeholder="Subject"
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="active">
              <InputGroup>
                <InputGroup.Addon>
                  <input type="checkbox" value={this.state.active}
                  onChange={this.handleChange}/>
                </InputGroup.Addon>
              </InputGroup>
            </FormGroup>
            <FormGroup bsSize="small" controlId="content">
              <FormControl
                onChange={this.handleChange}
                value={this.state.content}
                componentClass="textarea"
                placeholder="Description"
              />
            </FormGroup>
            <FormGroup controlId="file">
              <ControlLabel>Attachment</ControlLabel>
              <FormControl onChange={this.handleFileChange} type="file" />
            </FormGroup>
            <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Create"
              loadingText="Creating…"
            />
          </form>
        </div>
      );
    }
  }