import React from 'react';
import './CubeLoader.css';

const CubeLoader = ({ text }) => (
    <div className="cubecontainer" >
          <div className="cube">
            <div className="sides">
              <div className="top"></div>
              <div className="right"></div>
              <div className="bottom"></div>
              <div className="left"></div>
              <div className="front"></div>
              <div className="back"></div>
            </div>
          </div>
        <div className="cubetext">Loading {text}</div>
    </div>
);
        
export default CubeLoader;