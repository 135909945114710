import React, { Component } from "react";
import Settings from "./Settings";
import Times from "./Times";
import Controller from "./Controller";
import "./TomatoTimer.css";
// TODO: Agregar Botones de Bootstrap o algun estilo 
// alos botones de repeticion del pomodoro
// TODO: Hacerlo responsive no es responsive es statico
// TODO: Requieres generar un apurador algo que te diga no has avanzado tienes hora limite
// esto ultimo podria estar integrado con las notas o las notas tener un estilo especifico de esto
export default class TomatoTimer extends Component {
    constructor(props) {
        super(props);
    
        this.audioBeep = React.createRef();
    
        this.state = {
          breakLength: Number.parseInt(this.props.defaultBreakLength, 10),
          sessionLength: Number.parseInt(this.props.defaultSessionLength, 10),
          timeLabel: 'Session',
          timeLeftInSecond: Number.parseInt(this.props.defaultSessionLength, 10) * 60,
          isStart: false,
          timerInterval: null
        }
    
        this.onIncreaseBreak = this.onIncreaseBreak.bind(this);
        this.onDecreaseBreak = this.onDecreaseBreak.bind(this);
        this.onIncreaseSession = this.onIncreaseSession.bind(this);
        this.onDecreaseSession = this.onDecreaseSession.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onStartStop = this.onStartStop.bind(this);
        this.decreaseTimer = this.decreaseTimer.bind(this);
        this.phaseControl = this.phaseControl.bind(this);
      }
    
      onIncreaseBreak() {
        if (this.state.breakLength < 60 && !this.state.isStart) {
          this.setState({
            breakLength: this.state.breakLength + 1
          });
        }
      }
    
      onDecreaseBreak() {
        if (this.state.breakLength > 1 && !this.state.isStart) {
          this.setState({
            breakLength: this.state.breakLength - 1
          });
        }
      }
    
      onIncreaseSession() {
        if (this.state.sessionLength < 60 && !this.state.isStart) {
          this.setState({
            sessionLength: this.state.sessionLength + 1,
            timeLeftInSecond: (this.state.sessionLength + 1) * 60
          });
        }
      }
    
      onDecreaseSession() {
        if (this.state.sessionLength > 1 && !this.state.isStart) {
          this.setState({
            sessionLength: this.state.sessionLength - 1,
            timeLeftInSecond: (this.state.sessionLength - 1) * 60
          });
        }
      }
    
      onReset() {
        this.setState({
          breakLength: Number.parseInt(this.props.defaultBreakLength, 10),
          sessionLength: Number.parseInt(this.props.defaultSessionLength, 10),
          timeLabel: 'Session',
          timeLeftInSecond: Number.parseInt(this.props.defaultSessionLength, 10) * 60,
          isStart: false,
          timerInterval: null
        });
    
        this.audioBeep.current.pause();
        this.audioBeep.current.currentTime = 0;
        this.state.timerInterval && clearInterval(this.state.timerInterval);
      }
    
      onStartStop() {
        if (!this.state.isStart) {
          this.setState({
            isStart: !this.state.isStart,
            timerInterval: setInterval(() => {
              this.decreaseTimer();
              this.phaseControl();
            }, 1000)
          })
        } else {
          this.audioBeep.current.pause();
          this.audioBeep.current.currentTime = 0;
          this.state.timerInterval && clearInterval(this.state.timerInterval);
    
          this.setState({
            isStart: !this.state.isStart,
            timerInterval: null
          });
        }
      }
    
      decreaseTimer() {
        this.setState({
          timeLeftInSecond: this.state.timeLeftInSecond - 1
        });
      }
    
      phaseControl() {
        if (this.state.timeLeftInSecond === 0) {
          this.audioBeep.current.play();
        } else if (this.state.timeLeftInSecond === -1) {
          if (this.state.timeLabel === 'Session') {
            this.setState({
              timeLabel: 'Break',
              timeLeftInSecond: this.state.breakLength * 60
            });
          } else {
            this.setState({
              timeLabel: 'Session',
              timeLeftInSecond: this.state.sessionLength * 60
            });
          }
        }
      }
    
      render() {
        return (
          <div className="pomodoro-clock">
            <div className="pomodoro-clock-header">
              <h1 className="pomodoro-clock-header-name">pomodoro clock</h1>
            </div>
    
            <Settings
              breakLength={this.state.breakLength}
              sessionLength={this.state.sessionLength}
              isStart={this.state.isStart}
              onDecreaseBreak={this.onDecreaseBreak}
              onDecreaseSession={this.onDecreaseSession}
              onIncreaseBreak={this.onIncreaseBreak}
              onIncreaseSession={this.onIncreaseSession}
            />
    
            <Times
              timeLabel={this.state.timeLabel}
              timeLeftInSecond={this.state.timeLeftInSecond}
            />
    
            <Controller
              onReset={this.onReset}
              onStartStop={this.onStartStop}
              isStart={this.state.isStart}
            />
    
            <audio id="beep" preload="auto" src="https://goo.gl/65cBl1" ref={this.audioBeep}></audio>
        
          </div>
        );
      }
}