import React, { Component } from "react";
import Amplify, { API, Logger } from "aws-amplify";
import { Button } from "react-bootstrap";
import { Elements, StripeProvider } from "react-stripe-elements";
import BillingForm from "../BillingForm/BillingForm";
import config from "../../../../config";
import "./Settings.css";

Amplify.Logger.LOG_LEVEL = 'DEBUG';
export default class Settings extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  billUser(details) {
    return API.post("brainstorm", "/billing", {
      body: details
    });
  }

  createDashboardMap(dashboardMap) {
    return API.post("brainstorm", "/dashboardMap", {
      body: dashboardMap
    });
  }

  handleFormSubmit = async (storage, { token, error }) => {
    if (error) {
      alert(error);
      return;
    }
  
    this.setState({ isLoading: true });
  
    try {
      await this.billUser({
        storage,
        source: token.id
      });
  
      alert("Your card has been charged successfully!");
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }
  /*
   {i: 'CubeLoader', x: 0, y: 0, w: 2, h: 7, minW:2, minH:7},
      {i: 'CalendarHeatmap', x: 0, y: 6, w: 3, h: 3, minW: 3, minH: 3},
      {i: 'TomatoTimer', x: 8, y: 4, w: 2, h: 5, minW:2, minH: 2},
      {i: 'DayPhrases', x: 8, y: 4, w: 3, h: 2},
      {i: 'AddNotes', x: 0, y: 14, w: 4, h: 2}
  */
  handleTestSubmit = async event => {

    const logger = new Logger('foo');
    event.preventDefault();
    const dashboardMap = {i: 'Watch', x:4, y:10,w:2,h:2};
    
    this.setState({ isLoading: true });
  
    try {
      await this.createDashboardMap(dashboardMap);
  
      alert("Your dashboardMap is saved!");
      this.props.history.push("/");
    } catch (e) {
      logger.error('error happened', e);
      alert('->'+e);
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <div className="Settings">
        <div>
        <p>Section of Configurating templates or views How you prefer the session list big list , Simple 800px or 1200 centered, small cards, dashboard, way.</p>
        </div>
        <Button onClick={this.handleTestSubmit}>Test</Button>
      </div>
    );
  }
  
  renderStripePayFeature(){
    return (<div>
    <StripeProvider apiKey={config.STRIPE_KEY}>
      <Elements>
        <BillingForm
          loading={this.state.isLoading}
          onSubmit={this.handleFormSubmit}
        />
      </Elements>
    </StripeProvider>
    </div>
    )
  }

}
