  const dev = {
    STRIPE_KEY: "pk_test_mn4LGbwjSXqRPmRwoDkPwCiq",
    s3: {
      REGION: "us-east-1",
      BUCKET: "notes-app-s3files"
    },
    apiGateway: {
      REGION: "us-east-1",
      NotesURL: "https://9n800ehvz2.execute-api.us-east-1.amazonaws.com/dev",
      DayPhrasesURL: "https://dayphrases-api.brainstormlist.com/api"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_ztbipzPjt",
      APP_CLIENT_ID: "a3cl5m87h8j8uu382afafe73r",
      IDENTITY_POOL_ID: "us-east-1:079dab6c-86e9-44df-9407-cb8b948c294b"    },
    social: {
      FB: "2335060553393794"
    }
  };
  
  const prod = {
    STRIPE_KEY: "pk_test_mn4LGbwjSXqRPmRwoDkPwCiq",
    s3: {
      REGION: "us-east-1",
      BUCKET: "notes-app-s3files"
    },
    apiGateway: {
      REGION: "us-east-1",
      NotesURL: "https://wofkuee6r0.execute-api.us-east-1.amazonaws.com/prod",
      DayPhrasesURL: "https://dayphrases-api.brainstormlist.com/api"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_25bMDtVHM",
      APP_CLIENT_ID: "25gtoavrujj6k9iiatipukdhfr",
      IDENTITY_POOL_ID: "us-east-1:a7c883b3-6b53-45ee-bf3c-6ea486ddd8b8"
    },
    social: {
      FB: "2335060553393794"
    }
  };
  
  // Default to dev if not set
  const config = process.env.REACT_APP_STAGE === 'dev'
    ? prod
    : dev;
  
  export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
  };
  